//
// reboot.scss
//

@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap');

html {
    @apply relative scroll-smooth;
}


body {
    font-size: 17px;
    @apply   overflow-x-hidden text-gray-500;
}
 

#public-pages.public-pages{
    @apply text-gray-700;
    font-family: 'Be Vietnam Pro', 'sans-serif' !important;
    background-color: #fff;
    a{
        @apply no-underline;
    }
    p,h1,h2,h3,h4,h5,h6,div,li,td,span{
        font-family: 'Be Vietnam Pro', Inter, 'sans-serif' !important;
    }
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}
.footer-links{
    a{
        &:hover{
    text-decoration: underline;
   }
    }
   
}


input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}

h1,h2,h3,h4,h5,h6 {
    @apply font-semibold;
}

.vertical-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed;
}


:root {
    &:is([data-mode="dark"]) {
        color-scheme: dark;
    }
}



//
// _helper.scss
//


//  Animation  (Agency Home Page)
.hero-with-shapes {
    @keyframes rotating {
        0% {
            transform: rotate(0deg);
            top: 10%;
            left: 5%;
        }

        100% {
            transform: rotate(360deg);
            top: 60%;
            left: 15%;
        }
    }

    @keyframes rotating2 {
        0% {
            transform: rotate(0deg);
            bottom: 10%;
            right: 10%;
        }

        100% {
            transform: rotate(360deg);
            bottom: 80%;
            right: 30%;
        }
    }

    @keyframes rotating3 {
        0% {
            transform: rotate(0deg);
            bottom: 0%;
            right: 65%;
        }

        100% {
            transform: rotate(360deg);
            bottom: 50%;
            right: 35%;
        }
    }

    .shape1 {
        position: absolute;
        top: 5%;
        left: 5%;
        width: 7rem;
        height: 7rem;
        background: url('/assets/images/shapes/rounded-square2.svg');
        animation: rotating alternate ease-in-out infinite 6s;
    }

    .shape2 {
        position: absolute;
        bottom: 20%;
        right: 10%;
        width: 7rem;
        height: 7rem;
        background: url('/assets/images/shapes/rounded-square2.svg');
        animation: rotating2 alternate ease-in-out infinite 6s;
    }

    .shape3 {
        position: absolute;
        bottom: 0%;
        right: 65%;
        width: 7rem;
        height: 7rem;
        background: url('/assets/images/shapes/rounded-square2.svg');
        animation: rotating3 alternate ease-in-out infinite 6s;
    }
}


// 
// _navbar.scss 
// 

.logo-light {
    @apply hidden;
}

@screen lg {
    .logo-light {
        @apply hidden dark:block;
    }

    .logo-dark {
        @apply block dark:hidden;
    }
}

.navbar-nav {
    .nav-item {
        a{
            @apply  no-underline; 
        }
        .nav-link {
            @apply font-medium relative tracking-wide flex items-center py-1.5 px-3.5 text-gray-800 rounded-md text-lg cursor-pointer transition-all duration-300 bg-transparent no-underline;

            &:is(.active, :active, :focus, :hover) {
                @apply text-primary;
            }
        }

        .nav-item {
            .nav-item {
      
                .nav-link {
                    @apply text-gray-800;
                }
            }
        }
    }
}

// Navbar 
header {

    
    &.nav-sticky {
        @apply bg-white shadow;
    }

    &.dark {
        .navbar-nav {
            .nav-item {
                .nav-link {
                    @apply text-gray-100;

                    &:is(.active, :active, :focus, :hover) {
                        @apply text-primary;
                    }
                }

                .fc-dropdown {
                    .nav-link {
                        @apply text-gray-800;

                        &.active {
                            @apply text-primary;
                        }
                    }
                }
            }
        }

        &.nav-sticky {

            @screen lg {

                .logo-light {
                    @apply hidden;
                }

                .logo-dark {
                    @apply block;
                }
            }

            .navbar-nav {
                .nav-link {
                    @apply text-gray-800 hover:text-primary;

                    &:is(.active, :active, :focus, :hover) {
                        @apply text-primary;
                    }
                }
            }
        }
    }
}



//
// Gallery Demo
//

.filter-options li a {
    @apply text-gray-700 px-4 py-1.5 rounded border border-gray-300 flex items-center justify-center text-sm transition-all duration-500;
}

.filter-options li:hover a,
.filter-options li.active a,
.filter-options li:active a,
.filter-options li:focus a {
    @apply bg-primary text-white border-transparent shadow-md shadow-primary/25;
}

.icon-mask{
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
}

.postmessage{
    span{
        @apply text-blue-500;
    }
}